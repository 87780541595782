import Bubbles from '@/components/WordCloud/Bubbles';
import { ContentTypeName } from '@/constants';
import type { IWordCloud } from '@/interfaces';
import { RowContainer, ViewAllButton, WordBubblesWrapper } from './Row.styles';

const Row: React.FC<IWordCloud> = ({ alignment, buttonLink, wordBubbles }) => {
  // Currently, there is no use case for all Tags or all Markets, so we will use 1st bubble's content-type to determine the overall layout
  const isTag = wordBubbles[0].__typename === ContentTypeName.Tag;
  const bubbleHeight = isTag ? 34 : 32;

  return (
    <RowContainer>
      <WordBubblesWrapper bubbleHeight={bubbleHeight} alignment={alignment}>
        <Bubbles wordBubbles={wordBubbles} row={1} />
      </WordBubblesWrapper>
      {buttonLink && <ViewAllButton href={buttonLink}>View all</ViewAllButton>}
    </RowContainer>
  );
};

export default Row;
