import { Button } from '@/components';
import type { spacing } from '@/constants/theme/spacing';
import { ButtonSize, ButtonVariant, type IWordCloud } from '@/interfaces';
import styled, { type DefaultTheme } from 'styled-components';

const MOBILE_BUBBLE_GAP = 12;
const DESKTOP_BUBBLE_GAP = 16;
const DESKTOP_MAX_ROW = 3;
const MOBILE_MAX_ROW = 4;

const getWordBubblesMaxHeight = (bubbleHeight: number) => {
  return {
    mobile: (bubbleHeight + MOBILE_BUBBLE_GAP) * MOBILE_MAX_ROW,
    desktop: (bubbleHeight + DESKTOP_BUBBLE_GAP) * DESKTOP_MAX_ROW,
  };
};

const renderStyles: any = (theme: DefaultTheme, bubbleHeight: number, isMobile: boolean) => {
  const { mobile, desktop } = getWordBubblesMaxHeight(bubbleHeight);
  const maxHeight = isMobile ? mobile : desktop;
  const margin = ((isMobile ? MOBILE_BUBBLE_GAP : DESKTOP_BUBBLE_GAP) / 2) as keyof typeof spacing;

  return {
    maxHeight,
    margin: `-${theme.spacing[margin]}`,
    '> div > *': {
      margin: theme.spacing[margin],
    },
  };
};

export const WordBubblesWrapper = styled.div<{ alignment?: IWordCloud['alignment']; bubbleHeight: number }>(
  ({ theme, alignment, bubbleHeight }) => ({
    '> div': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: alignment === 'Center' ? 'center' : 'flex-start',
    },
    overflow: 'hidden',
    ...renderStyles(theme, bubbleHeight, true),

    [`${theme.mediaBreakpointUp.md}`]: {
      paddingTop: 0,

      ...renderStyles(theme, bubbleHeight, false),
    },
  }),
);

export const RowContainer = styled.div(({ theme }) => ({
  padding: `0 ${theme.spacing[20]}`,
}));

export const ViewAllButton = styled(Button).attrs({
  variant: ButtonVariant.Secondary,
  size: ButtonSize.Large,
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing[20],

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: theme.spacing[24],
  },
}));
